import React, { Component } from "react";
import { G } from "../globals";

class OverlayDeveloper extends Component {
  render() {
    return (
      <div className="overlay clickable">
        <div className="overlay-content">
          <div className="slide-close-container">
            <div
              className="slide-close"
              onClick={() => G.showOverlay("showDev", false)}
            ></div>
          </div>
          <div className="overlay-main-container">
            <div className="development-content">
              <div className="development-col-left">
                <h2 className="overlay-title">Development team</h2>
                <div className="development-cell developer">
                  <div className="development-label">Funding Partner</div>
                  <a
                    href="https://www.invesco.com/"
                    target="_blank"
                    className="invesco-logo block-display"
                  ></a>
                </div>
                <div className="developer-text">
                  Invesco Real Estate is a global real estate investment manager
                  with over 36 years experience developing and investing across
                  commercial (office, logistics and retail), residential and
                  hotel asset classes. <br />‍<br />
                  With c.US$80.3bn of asset under management and over 680 assets
                  across 33 countries, our deep real estate experience and track
                  record means we are one of the largest real estate firms
                  globally and work with the interests of our investors, our
                  tenants, our business partners and other stakeholders at
                  heart.{" "}
                </div>
                <div className="development-cell developer">
                  <div className="development-label">Developer</div>
                  {/* <a
                    href="https://yardnine.com"
                    target="_blank"
                    className="yardnine-logo dev-yardnine-logo block-display"
                  ></a> */}
                </div>
                <div className="developer-text">
                  YardNine develops across the spectrum of asset classes, from
                  new exciting workplaces to stunning homes and vibrant retail.
                  For every project we strive to create the best possible
                  environment for everyone who interacts with our buildings,
                  whether residents, workers, guests or visitors.
                </div>
                <div className="developer-images-wrapper">
                  <div className="developer-image-cell">
                    <div className="developer-image fitzroy-image"></div>
                    <div className="developer-image-label">
                      Fitzroy Place,
                      <br />
                      London W1
                    </div>
                  </div>
                  <div className="developer-image-cell">
                    <div className="developer-image bedford-image"></div>
                    <div className="developer-image-label">
                      One Bedford Avenue,
                      <br />
                      London W1
                    </div>
                  </div>
                </div>
                <div className="developer-images-wrapper">
                  <div className="developer-image-cell">
                    <div className="developer-image exchange-image"></div>
                    <div className="developer-image-label">
                      The London Fruit and Wool Exchange,
                      <br />
                      London E1
                    </div>
                  </div>
                  <div className="developer-image-cell">
                    <div className="developer-image burlington-image"></div>
                    <div className="developer-image-label">
                      10 New Burlington Street,
                      <br />
                      London W1
                    </div>
                  </div>
                </div>
              </div>
              <div className="development-col-right">
                <div className="development-cell">
                  <div className="development-label">Project Manager</div>
                  <div className="avison-logo"></div>
                </div>
                <div className="development-cell">
                  <div className="development-label">Architect</div>
                  <div className="sheppard-logo"></div>
                </div>
                <div className="development-cell">
                  <div className="development-label">Structural Engineer</div>
                  <div className="aecom-logo"></div>
                </div>
                <div className="development-cell">
                  <div className="development-label">Services Engineer</div>
                  <div className="watkins-logo"></div>
                </div>
                <div className="development-cell">
                  <div className="development-label">Quantity Surveyor</div>
                  <div className="gt-logo"></div>
                </div>
                <div className="development-cell">
                  <div className="development-label">Main Contractor</div>
                  <div className="mclaren-logo"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="overlay-bg blue-bg"></div>
      </div>
    );
  }
}

export default OverlayDeveloper;
