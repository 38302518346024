import React, { Component } from "react";
import { G } from "../globals";

class Nav extends Component {
  constructor() {
    super();

    this.isBurgerOpen = false;
    this.isBurgerProgress = false;
  }

  toggleBurger() {
    if (this.isBurgerProgress) return;
    if (this.isBurgerOpen) this.closeBurger();
    else this.openBurger();
  }

  openBurger() {
    if (this.isBurgerOpen) return;
    this.burgerWrapper.classList.add("burger-open");
    document.body.classList.add("no-scroll");
    this.isBurgerProgress = true;
    this.burgerButton.classList.add("burger-close-button");
    this.burgerBg.classList.remove("hidden");
    this.burgerContent.classList.remove("hidden");
    this.logo.classList.add("burger-open");
    const navItems = [...this.navList.querySelectorAll(".nav-item")];
    navItems.map((n) => n.classList.add("burger-active"));
    setTimeout(() => {
      this.burgerBg.classList.add("burger-open");
      this.burgerFiftyAddress.classList.add("burger-open");

      const burgerListItems = [
        ...this.burgerNavList.querySelectorAll(".burger-item"),
      ];
      burgerListItems.map((b, i) => {
        setTimeout(() => {
          b.classList.add("burger-open");
        }, 0 + i * 200);
      });
      setTimeout(() => {
        this.burgerFiftyLines.classList.add("burger-open");
      }, 800);
      this.isBurgerOpen = true;
      setTimeout(() => {
        this.isBurgerProgress = false;
      }, 500);
    }, 100);
  }

  closeBurger() {
    if (!this.isBurgerOpen) return;

    this.isBurgerProgress = true;
    this.burgerButton.classList.remove("burger-close-button");
    const navItems = [...this.navList.querySelectorAll(".nav-item")];
    navItems.map((n) => n.classList.remove("burger-active"));
    this.burgerFiftyAddress.classList.remove("burger-open");
    this.burgerFiftyLines.classList.remove("burger-open");
    const burgerListItems = [
      ...this.burgerNavList.querySelectorAll(".burger-item"),
    ];
    burgerListItems.map((b) => b.classList.remove("burger-open"));
    setTimeout(() => {
      this.burgerBg.classList.remove("burger-open");
      this.logo.classList.remove("burger-open");
      setTimeout(() => {
        this.burgerBg.classList.add("hidden");
        this.burgerContent.classList.add("hidden");
        this.isBurgerOpen = false;
        this.burgerWrapper.classList.remove("burger-open");
        document.body.classList.remove("no-scroll");
        this.isBurgerProgress = false;
      }, 400);
    }, 200);
  }

  burgerGoToStateHandler(state) {
    this.closeBurger();
    this.props.GoToState(state);
  }

  burgerOverlayHandler(overlay, args = true) {
    this.closeBurger();
    G.showOverlay(overlay, args);
  }

  setNav(name) {
    if (!this.props.currentState.name) return "";
    if (this.props.currentState.name.toLowerCase() === name)
      return "nav-active";
    else return "";
  }

  componentDidMount() {
    if (this.props.currentState.name === "CONTACT") this.hideLogo(true);
    else this.hideLogo(false);
  }

  componentDidUpdate() {
    if (this.props.currentState.name === "CONTACT") this.hideLogo(true);
    else this.hideLogo(false);
  }

  hideLogo(bool = true) {
    if (bool) {
      this.logo.classList.add("fade");
    } else {
      this.logo.classList.remove("fade");
    }
  }

  render() {
    return (
      <div
        className={`nav ${
          this.props.currentState.name.toLowerCase() === "feature" ||
          this.props.currentState.name.toLowerCase() === "intro"
            ? "fade"
            : ""
        }`}
      >
        <div
          ref={(ref) => (this.logo = ref)}
          className="fifty-nav-logo clickable"
          onClick={() => this.props.GoToState("OVERVIEW")}
        ></div>
        <ul
          ref={(ref) => (this.navList = ref)}
          className="nav-list w-list-unstyled clickable"
        >
          <li
            onClick={() => this.props.GoToState("OVERVIEW")}
            className={`nav-item ${this.setNav("overview")}`}
          >
            <div className="nav-item-label">overview</div>
            <div className="nav-item-line"></div>
          </li>
          <li
            onClick={() => this.props.GoToState("BUILDING")}
            className={`nav-item ${this.setNav("building")}`}
          >
            <div className="nav-item-label">building</div>
            <div className="nav-item-line"></div>
          </li>
          <li
            onClick={() => this.props.GoToState("LOCATION")}
            className={`nav-item ${this.setNav("location")}`}
          >
            <div className="nav-item-label">location</div>
            <div className="nav-item-line"></div>
          </li>
          <li
            onClick={() => this.props.GoToState("CONTACT")}
            className={`nav-item ${this.setNav("contact")}`}
          >
            <div className="nav-item-label">contact &amp; downloads</div>
            <div className="nav-item-line"></div>
          </li>
        </ul>
        <div
          ref={(ref) => (this.burgerButton = ref)}
          onClick={() => this.toggleBurger()}
          className="burger-button clickable"
        ></div>
        <div
          ref={(ref) => (this.burgerWrapper = ref)}
          className="burger-wrapper "
        >
          <div
            ref={(ref) => (this.burgerBg = ref)}
            className="burger-bg hidden"
          ></div>
          <div
            ref={(ref) => (this.burgerContent = ref)}
            className="burger-content clickable hidden"
          >
            <div className="burger-left-col">
              <div
                ref={(ref) => (this.burgerFiftyAddress = ref)}
                className="fifty-address"
              >
                50 Eastbourne Terrace, W2 6LG
              </div>
              <div
                ref={(ref) => (this.burgerFiftyLines = ref)}
                className="burger-fifty-lines"
              >
                13,500 – 75,000 sq ft,
                <br />
                corner building,
                <br />
                perfectly positioned,
                <br />
                high quality,
                <br />
                rooftop views,
                <br />
                natural light,
                <br />
                travel hub,
                <br />
                international access,
                <br />
                elizabeth line,
                <br />
                street eats,
                <br />
                fresh air,
                <br />
                <span className="fifty-text-logo white-logo">fifty</span>
              </div>
            </div>
            <ul
              ref={(ref) => (this.burgerNavList = ref)}
              className="nav-list burger-list w-list-unstyled"
            >
              <li className="burger-item">
                <div
                  className="burger-item-label burger-mobile-item"
                  onClick={() => this.burgerGoToStateHandler("OVERVIEW")}
                >
                  overview
                </div>
                <div
                  className="burger-item-label"
                  onClick={() => this.burgerGoToStateHandler("FEATURE_SMART")}
                >
                  smart
                </div>
                <div
                  className="burger-item-label"
                  onClick={() =>
                    this.burgerGoToStateHandler("FEATURE_CONNECTED")
                  }
                >
                  connected
                </div>
                <div
                  className="burger-item-label"
                  onClick={() => this.burgerGoToStateHandler("FEATURE_HEALTHY")}
                >
                  healthy
                </div>
                <div
                  className="burger-item-label"
                  onClick={() =>
                    this.burgerGoToStateHandler("FEATURE_RESPONSIBLE")
                  }
                >
                  responsible
                </div>
                <div
                  className="burger-item-label"
                  onClick={() => this.burgerGoToStateHandler("FEATURE_QUALITY")}
                >
                  quality
                </div>
              </li>
              <li className="burger-item">
                <div
                  className="burger-item-label burger-mobile-item"
                  onClick={() => this.burgerGoToStateHandler("BUILDING")}
                >
                  building
                </div>
                <div
                  className="burger-item-label"
                  onClick={() => this.burgerGoToStateHandler("FOURTH")}
                >
                  floors
                </div>
                <div
                  className="burger-item-label"
                  onClick={() => {
                    this.burgerOverlayHandler("showGallery", [
                      "./images/gallery/ext1.jpg",
                      "./images/gallery/ext2.jpg",
                      "./images/gallery/ext3.jpg",
                      "./images/gallery/ext4.jpg",
                      "./images/gallery/am1.jpg",
                      "./images/gallery/am2.jpg",
                      "./images/gallery/am3.jpg",
                      "./images/gallery/rec1.jpg",
                      "./images/gallery/rec2.jpg",
                      "./images/gallery/rec3.jpg",
                      "./images/gallery/sec1.jpg",
                      "./images/gallery/sec2.jpg",
                      "./images/gallery/sec3.jpg",
                      "./images/gallery/fif1.jpg",
                      "./images/gallery/fif2.jpg",
                      "./images/gallery/ter1.jpg",
                      "./images/gallery/fit1a.jpg",
                      "./images/gallery/fit2.jpg",
                      // "./images/gallery/fit3.jpg",
                      "./images/gallery/ter1.jpg",
                      // "./images/gallery/ter2.jpg",
                      // "./images/gallery/ter3.jpg",
                      "./images/gallery/ter4.jpg",
                      "./images/gallery/sh1.jpg",
                      "./images/gallery/sh2.jpg",
                      "./images/gallery/sh3.jpg",
                      "./images/gallery/ext5.jpg",
                      "./images/gallery/ext6.jpg",
                      "./images/gallery/ext4.jpg",
                      "./images/gallery/new-img1.jpg",
                      "./images/gallery/new-img2.jpg",
                      "./images/gallery/new-img3.jpg",
                      // "./images/gallery/new-img4.jpg",
                      "./images/gallery/new-img5.jpg",
                      "./images/gallery/new-img6.jpg",
                      "./images/gallery/new-img7.jpg",
                      "./images/gallery/new-img8.jpg",
                    ]);
                  }}
                >
                  gallery
                </div>
                <div
                  className="burger-item-label"
                  onClick={() => this.burgerOverlayHandler("showSpec")}
                >
                  specifications
                </div>
              </li>
              <li className="burger-item">
                <div
                  className="burger-item-label"
                  onClick={() => this.burgerGoToStateHandler("LOCATION")}
                >
                  amenities
                </div>
                <div
                  className="burger-item-label"
                  onClick={() =>
                    this.burgerOverlayHandler(
                      "showVideo",
                      "https://vimeo.com/646928573/54e3ea698f"
                    )
                  }
                >
                  film
                </div>
                <div className="burger-divider"></div>
                <div
                  className="burger-item-label"
                  onClick={() => this.burgerGoToStateHandler("ELIZABETH")}
                >
                  elizabeth line
                </div>
                <div
                  className="burger-item-label"
                  onClick={() => this.burgerGoToStateHandler("UNDERGROUND")}
                >
                  underground
                </div>
                <div
                  className="burger-item-label"
                  onClick={() => this.burgerGoToStateHandler("RAIL")}
                >
                  national rail
                </div>
              </li>
              <li className="burger-item">
                <div
                  className="burger-item-label"
                  onClick={() => this.burgerGoToStateHandler("CONTACT")}
                >
                  contact
                </div>
                <div
                  className="burger-item-label"
                  onClick={() => this.burgerGoToStateHandler("CONTACT")}
                >
                  downloads
                </div>
                <div
                  className="burger-item-label"
                  onClick={() => this.burgerOverlayHandler("showDev")}
                >
                  development team
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Nav;
